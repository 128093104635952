import React from "react";
import {
  faSort,
  faSortAsc,
  faSortDesc,
  faSortNumericAsc,
  faSortNumericDesc,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useUrlParams from "../../../utils/useUrlParams";
import TableRow from "./TableRow";
// import TableRow from "./TableRow";

const AgentManageFxRateTable: React.FC<any> = (props: any) => {
  const { data, sort, filter, isLoading ,editMode,editedRows, setEditedRows} = props;
  
  const { urlParamsHandler } = useUrlParams();
  return (
    <>
      <table
        className="table table-bordered"
        id="dataTable"
        width="100%"
        cellSpacing={0}
      >
        <thead>
          <tr>
            <th
              className="cursor-pointer"
              onClick={() => {
                !sort.orderId && urlParamsHandler("sort", { orderId: "asc" });
                sort.orderId === "asc" &&
                  urlParamsHandler("sort", { orderId: "desc" });
                sort.orderId === "desc" && urlParamsHandler("sort", "");
              }}
            >
              CURRENCY NAME
              {(!sort.orderId && <FontAwesomeIcon icon={faSort} />) ||
                (sort.orderId === "asc" && (
                  <FontAwesomeIcon icon={faSortAsc} />
                )) ||
                (sort.orderId === "desc" && (
                  <FontAwesomeIcon icon={faSortDesc} />
                ))}
            </th>
            <th
              className="cursor-pointer"
              onClick={() => {
                !sort.createdAt &&
                  urlParamsHandler("sort", { createdAt: "desc" });
                sort.createdAt === "desc" &&
                  urlParamsHandler("sort", { createdAt: "asc" });
                sort.createdAt === "asc" &&
                  urlParamsHandler("sort", { createdAt: "desc" });
              }}
            >
              SETTLEMENT FOR EDUCATIONAL REMITENCE{" "}
              {(!sort.createdAt && <FontAwesomeIcon icon={faSort} />) ||
                (sort.createdAt === "asc" && (
                  <FontAwesomeIcon icon={faSortAsc} />
                )) ||
                (sort.createdAt === "desc" && (
                  <FontAwesomeIcon icon={faSortDesc} />
                ))}
            </th>
            {/* <th
              className="cursor-pointer"
              onClick={() => {
                !sort.product && urlParamsHandler("sort", { product: "asc" });
                sort.product === "asc" &&
                  urlParamsHandler("sort", { product: "desc" });
                sort.product === "desc" && urlParamsHandler("sort", "");
              }}
            >
              SETTLEMENT FOR OTHER REMITENCE{" "}
              {(!sort.product && <FontAwesomeIcon icon={faSort} />) ||
                (sort.product === "asc" && (
                  <FontAwesomeIcon icon={faSortAsc} />
                )) ||
                (sort.product === "desc" && (
                  <FontAwesomeIcon icon={faSortDesc} />
                ))}
            </th> */}
            {/* <th
              className="cursor-pointer"
              onClick={() => {
                !sort.foreignCurrency &&
                  urlParamsHandler("sort", { foreignCurrency: "asc" });
                sort.foreignCurrency === "asc" &&
                  urlParamsHandler("sort", { foreignCurrency: "desc" });
                sort.foreignCurrency === "desc" && urlParamsHandler("sort", "");
              }}
            >
              SETTLEMENT FOR TRAVEL CARD{" "}
              {(!sort.foreignCurrency && <FontAwesomeIcon icon={faSort} />) ||
                (sort.foreignCurrency === "asc" && (
                  <FontAwesomeIcon icon={faSortAsc} />
                )) ||
                (sort.foreignCurrency === "desc" && (
                  <FontAwesomeIcon icon={faSortDesc} />
                ))}
            </th> */}
            <th
              className="cursor-pointer"
              onClick={() => {
                !sort.foreignCurrency &&
                  urlParamsHandler("sort", { foreignCurrency: "asc" });
                sort.foreignCurrency === "asc" &&
                  urlParamsHandler("sort", { foreignCurrency: "desc" });
                sort.foreignCurrency === "desc" && urlParamsHandler("sort", "");
              }}
            >
              EDUCATION REMITENCE MARGIN{" "}
              {(!sort.foreignCurrency && <FontAwesomeIcon icon={faSort} />) ||
                (sort.foreignCurrency === "asc" && (
                  <FontAwesomeIcon icon={faSortAsc} />
                )) ||
                (sort.foreignCurrency === "desc" && (
                  <FontAwesomeIcon icon={faSortDesc} />
                ))}
            </th>
            {/* <th
              className="cursor-pointer"
              onClick={() => {
                !sort.foreignCurrency &&
                  urlParamsHandler("sort", { foreignCurrency: "asc" });
                sort.foreignCurrency === "asc" &&
                  urlParamsHandler("sort", { foreignCurrency: "desc" });
                sort.foreignCurrency === "desc" && urlParamsHandler("sort", "");
              }}
            >
              OTHER REMITENCE MARGIN{" "}
              {(!sort.foreignCurrency && <FontAwesomeIcon icon={faSort} />) ||
                (sort.foreignCurrency === "asc" && (
                  <FontAwesomeIcon icon={faSortAsc} />
                )) ||
                (sort.foreignCurrency === "desc" && (
                  <FontAwesomeIcon icon={faSortDesc} />
                ))}
            </th> */}
            {/* <th
              className="cursor-pointer"
              onClick={() => {
                !sort.totalPayable &&
                  urlParamsHandler("sort", { totalPayable: "asc" });
                sort.totalPayable === "asc" &&
                  urlParamsHandler("sort", { totalPayable: "desc" });
                sort.totalPayable === "desc" && urlParamsHandler("sort", "");
              }}
            >
              TRAVEL CARD MARGIN{" "}
              {(!sort.totalPayable && <FontAwesomeIcon icon={faSort} />) ||
                (sort.totalPayable === "asc" && (
                  <FontAwesomeIcon icon={faSortNumericAsc} />
                )) ||
                (sort.totalPayable === "desc" && (
                  <FontAwesomeIcon icon={faSortNumericDesc} />
                ))}
            </th> */}
            <th className="cursor-pointer"></th>
          </tr>
        </thead>
        <tbody>
          {data && data?.length > 0
            ? data?.map((d: any,index:any) => <TableRow row={d} from={data?.isRates} editMode={editMode} editedRows={editedRows} setEditedRows={setEditedRows} index={index} />)
            : ""}
          {!isLoading && data && data?.length <= 0 ? (
            <tr>
              <td colSpan={9}>No Data Found!</td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>
    </>
  );
};

export default AgentManageFxRateTable;