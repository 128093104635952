import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import ROUTE_PATH from "../route/routes";
import ChannelPartnerSideNavbar from "../components/channelPartner/sideNavbar";
import ChannelPartnerHeader from "../components/channelPartner/header";
import MobileHeader from "../components/mobileHeader";
import { useAppSelector } from "../store/hooks";
import useUserNavigate from "../utils/useUserNavigate";
import { confirmAlert } from "react-confirm-alert";

const ChannelPartnerLayout: React.FC = () => {
  const { user, isAuthenticated, profile } = useAppSelector(
    (state: any) => state.user
  );
  const location = useLocation();
  const { userNavigate } = useUserNavigate();
  const navigate = useNavigate();

  // State to track if modal has been shown
  const [modalShown, setModalShown] = useState(false);
  useEffect(() => {
    if (
      !modalShown &&
      profile?.kycStatus === "pending" &&
      location.pathname !==
        ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE
    ) {
      setModalShown(true); // Set modal as shown
      confirmAlert({
        customUI: (props) => {
          return (
            <div
              className="react-confirm-alert-body col-md-12"
              style={{ zIndex: 999999999999999, backgroundColor: "#dff4f5" }}
            >
              <p className="mt-4">
                You need to submit and verify profile to be an authorised Forex
                Partner
              </p>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center my-4"
              >
                <button
                  className="btn btn-md btn-primary"
                  onClick={() => {
                    userNavigate(
                      ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE
                    );
                    props?.onClose();
                  }}
                >
                  Verify
                </button>
              </div>
            </div>
          );
        },
      });
    } else if (
      !modalShown &&
      profile?.kycStatus === "submitted" &&
      location.pathname !==
        ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE
    ) {
      setModalShown(true); // Set modal as shown
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div
              className="react-confirm-alert-body col-md-12"
              style={{ zIndex: 999999999999999, backgroundColor: "#dff4f5" }}
            >
              <p className="mt-4">
                Your KYC documents submitted. Please wait for approval.
              </p>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center my-4"
              >
                <button
                  className="btn btn-md btn-primary"
                  onClick={() => {
                    onClose(); // Close the modal before navigating
                    userNavigate(
                      ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE
                    );
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          );
        },
      });
    }
  }, [profile?.kycStatus, userNavigate, location.pathname, modalShown]);
  useEffect(() => {
    if (
      (profile?.kycStatus === "submitted" ||
        profile?.kycStatus === "pending") &&
      location.pathname !==
        ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE
    ) {
      navigate(ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE);
    }
  }, [location.pathname, profile?.kycStatus, navigate]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  if ("cpartner" !== user?.role?.toLowerCase()) {
    return (
      <Navigate
        to={isAuthenticated ? ROUTE_PATH.ERROR.ERROR_403 : ROUTE_PATH.AUTH.MAIN}
      />
    );
  }

  return (
    <>
      <MobileHeader />
      <div id="layoutSidenav">
        <ChannelPartnerSideNavbar />
        <div id="layoutSidenav_content">
          <main className="h-100">
            <div className="container-fluid layoutSidenav_content">
              <ChannelPartnerHeader />
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ChannelPartnerLayout;
