import { toast } from "react-toastify";
import API from "../config/api";
import service from "../utils/service";

const AgentService = {
  getProfile: async () => {
    try {
      const res = await service.get(API.PROFILE);
      if (res?.data) {
        return res.data;
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      return error;
    }
  },
  getDashboardData: async (props: any) => {
    const type = props?.type || "monthly";
    const product = props?.product || "";
    const limit = props?.type === "monthly" ? 12 : 6;
    try {
      const res = await service.get(
        `${API.AGENT_DASHBOARD}?type=${type}&limit=${limit}&product=${product}`,
      );
      if (res?.data) {
        return res.data;
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      return error;
    }
  },
  saveProfile: async (data: any) => {
    try {
      console.log("sdsds");
      
      const res = await service.put(API.AGENT_SAVE_PROFILE, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      if (res?.data) {
        
        return res.data;
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      return error;
    }
  },
  uploadDocument: async (document: File, type: string) => {
    try {
      const formData = new FormData();
      formData.append("image", document);
      formData.append("type", type);
      const res = await service.post(API.AGENT_PROFILE_KYC_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data) {
        return res?.data;
      }
    } catch (error) {
      //console.log(error);
    }
  },
  approveKyc: async () => {
    try {
      const res = await service.post(API.AGENT_PROFILE_KYC_APPROVE);
      if (res?.data) {
        return res?.data;
      }
    } catch (error) {
      //console.log(error);
    }
  },
  getRecentSender: async () => {
    try {
      const res = await service.get(API.AGENT_GET_RECENT_SENDER);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  getRecentCustomer: async () => {
    try {
      const res = await service.get(API.AGENT_RECENT_CUSTOMER);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  getOrderList: async (
    page: number,
    query: string,
    filter: string,
    sort: string,
    studentQuery?: string,
  ) => {
    try {
      // const res = await service.get(
      //   `${API.AGENT_GET_ORDER_LIST}?page=${page}&searchPartial=${
      //     query || ""
      //   }&filters=${filter}&sort=${sort}`,
      // );
      const res = await service.get(
        `${API.AGENT_GET_ORDER_LIST}?page=${page}&searchPartial=${
          query || ""
        }&searchStudent=${studentQuery||""}&filters=${filter}&sort=${sort}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  getOrderDetails: async (id: string, isSma?: boolean | true) => {
    try {
      const res = await service.get(
        `${
          isSma
            ? API.AGENT_GET_SMA_ORDER_DETAILS
            : API.AGENT_GET_CE_TC_ORDER_DETAILS
        }/${id}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  postLog: async (id: string, message?: string, files?: any) => {
    try {
      const formData = new FormData();
      formData.append("orderId", id);
      message && formData.append("message", message);
      if (files) {
        for (let i = 0; i <= files.length - 1; i++) {
          formData.append("files", files[i]);
        }
      }

      const res = await service.post(API.AGENT_POST_ORDER_LOG, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  editLog: async (id: string, message?: string, files?: any) => {
    try {
      const formData = new FormData();
      formData.append("orderId", id);
      message && formData.append("message", message);
      if (files) {
        for (let i = 0; i <= files.length - 1; i++) {
          formData.append("files", files[i]);
        }
      }

      const res = await service.put(API.AGENT_EDIT_ORDER_LOG.replace(":id",id), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  getOrderLogs: async (id: string, page: number) => {
    try {
      const res = await service.get(
        `${API.AGENT_GET_ORDER_LOG}/${id}?size=10&page=${page}&sort={"createdAt": "desc"}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  getLogFileUrl: async (bucket: string, key: string) => {
    try {
      const res = await service.get(
        `${API.GET_ORDER_LOG_FILE_URL}?bucket=${bucket}&key=${key}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  getReceiverList: async (page: number,search:any | null) => {
    try {
      const res = await service.get(
        `${API.AGENT_GET_RECEIVER_LIST}/?page=${page}&size=10&search=${search}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  selectReceiver: async (moneyTransferId: string, receiverId: string,feild70:any="") => {
    try {
      const res = await service.post(API.AGENT_SELECT_RECEIVER, {
        moneyTransferId: moneyTransferId,
        receiverId: receiverId,
        field70:feild70
      });
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  createReceiverDetails: async (params: any = {}) => {
    try {
      const res = await service.post(API.AGENT_CREATE_RECEIVER, {
        ...params,
      });
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      //console.log(error);
      return error;
    }
  },

  getUserList: async (page: number,query:any, filter:any, sort:any) => {
    try {
      const res = await service.get(
        `${API.AGENTADMIN_USER}/?page=${page}&searchPartial=${
          query || ""
        }`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
         

      return error;
    }
  },
  getUserById: async (id: any) => {
    try {
      const res = await service.get(
        `${API.GET_BRANCH_BY_ID.replace(":id",id)}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      

      return error;
    }
  },
  getFXRateList: async (page: number,query:any, filter:any, sort:any) => {
    try {
      const res = await service.get(
        `${API.AGENTADMIN_FX_RATES_LIST}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      

      return error;
    }
  },
  getCuurencyList: async () => {
    try {
      const res = await service.get(
        `${API.AGENT_CURRENCIES}`,
      );
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      

      return error;
    }
  },
  createBranch: async (params: any = {}) => {
    try {
      const res = await service.post(API.AGENTADMIN_USER, {
        ...params,
      });
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      //console.log(error);
      
      return error;

    }
  },
  EditBranch: async (params: any = {},id:any) => {
    try {
      console.log("ss");
      
      const res = await service.put(API.Edit_BRANCH_BY_ID.replace(":id",id), {
        ...params,
      });
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      //console.log(error);
      
      return error;
    }

  },
  deleteUser: async (id:any) => {
    try {
      const res = await service.delete(API.DELETE_BRANCH_BY_ID.replace(":id",id));
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      //console.log(error);
     
      return error;

    }
  },
  editFxRate:async(values:any)=>{
    try {
      const res = await service.put(API.EDIT_FX_RATES_LIST,{data:values});
      console.log(res);
      if(res.data){
        return res.data
      }
    } catch (error) {
      
      return error;
    }
  }
};
export default AgentService;
