import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTE_PATH from "./routes";

import Loader from "../components/loader";
import ChannelPartnerLayout from "../layouts/channelPartnerLayout";
import AgentManageFxRate from "../pages/agent/manageFXRate";

const AuthLayout = lazy(() => import("../layouts/authLayout"));
const StaffLayout = lazy(() => import("../layouts/staffLayout"));
const AdminLayout = lazy(() => import("../layouts/adminLayout"));
const CustomerLayout = lazy(() => import("../layouts/customerLayout"));
const AgentLayout = lazy(() => import("../layouts/agentLayout"));
const BranchLayout = lazy(() => import("../layouts/branchLayout"));
const AgentBusinessLayout = lazy(() => import("../layouts/agentBuisnessPartnerLayout"));

const ForgotPassword = lazy(() => import("../pages/public/forgotPassword"));
const Landing = lazy(() => import("../pages/public/newLanding"));
const PrivacyPolicy = lazy(() => import("../pages/public/privacyPolicy"));
const TermsAndConditions = lazy(() => import("../pages/public/termsAndCondition"));
const AboutUs = lazy(() => import("../pages/public/aboutUs"));
const Faq = lazy(() => import("../pages/public/faq"));
const Login = lazy(() => import("../pages/public/login"));
const Otp = lazy(() => import("../pages/public/otp"));
const Register = lazy(() => import("../pages/public/register"));
const ResetPassword = lazy(() => import("../pages/public/resetPassword"));

const Error = lazy(() => import("../pages/common/Error"));

const SenderDetails = lazy(
  () => import("../pages/public/sendMoneyAbroad/senderDetails"),
);
const SendMoneyAbroadLayout = lazy(
  () => import("../layouts/sendMoneyAbroadLayout"),
);
const ReceiverDetails = lazy(
  () => import("../pages/public/sendMoneyAbroad/receiverDetails"),
);
const CheckList = lazy(
  () => import("../pages/public/sendMoneyAbroad/checklist"),
);
const OrderReview = lazy(
  () => import("../pages/public/sendMoneyAbroad/orderReview"),
);
const PaymentMode = lazy(
  () => import("../pages/public/sendMoneyAbroad/paymentMode"),
);
const OrderReceived = lazy(
  () => import("../pages/public/sendMoneyAbroad/orederReceived"),
);
const CustomerAllReceivers = lazy(
  () => import("../pages/customer/allReceivers"),
);
const CustomerSMAPurposeCurrency = lazy(
  () => import("../pages/customer/newFundTransfer/purposeCurrency"),
);
const CustomerSMASenderDetails = lazy(
  () => import("../pages/customer/newFundTransfer/senderDetails"),
);
const CustomerSMAReceiverDetails = lazy(
  () => import("../pages/customer/newFundTransfer/receiverDetails"),
);
const CustomerSMACheckList = lazy(
  () => import("../pages/customer/newFundTransfer/checklist"),
);
const CustomerSMAOrderReview = lazy(
  () => import("../pages/customer/newFundTransfer/orderReview"),
);
const CustomerSMAPaymentMode = lazy(
  () => import("../pages/customer/newFundTransfer/paymentMode"),
);
const CustomerSMAOrderReceived = lazy(
  () => import("../pages/customer/newFundTransfer/orederReceived"),
);
const CustomerSMASelectReceiver = lazy(
  () => import("../pages/customer/newFundTransfer/selectReceiver"),
);
const CustomerSMASelectReceiverList = lazy(
  () => import("../pages/customer/newFundTransfer/selectReceiverList"),
);
const SelectLocation = lazy(
  () => import("../pages/public/currencyExchange/selectLocation"),
);
const CustomerDetails = lazy(
  () => import("../pages/public/currencyExchange/customerDetails"),
);
const CurrencyExchangeChecklist = lazy(
  () => import("../pages/public/currencyExchange/checklist"),
);
const CurrencyExchangeOrderReview = lazy(
  () => import("../pages/public/currencyExchange/orderReview"),
);
const CurrencyExchangePaymentMode = lazy(
  () => import("../pages/public/currencyExchange/paymentMode"),
);
const CurrencyExchangeOrderReceived = lazy(
  () => import("../pages/public/currencyExchange/orederReceived"),
);
const CurrencyExchangeLayout = lazy(
  () => import("../layouts/currencyExchangeLayout"),
);
const CustomerCurrencyExchangeMain = lazy(
  () => import("../pages/customer/currencyExchange/main"),
);
const CustomerCurrencyExchangeSelectLocation = lazy(
  () => import("../pages/customer/currencyExchange/selectLocation"),
);
const CustomerCurrencyExchangeCustomerDetails = lazy(
  () => import("../pages/customer/currencyExchange/customerDetails"),
);
const CustomerCurrencyExchangePaymentMode = lazy(
  () => import("../pages/customer/currencyExchange/paymentMode"),
);
const CustomerCurrencyExchangeOrderReview = lazy(
  () => import("../pages/customer/currencyExchange/orderReview"),
);
const CustomerCurrencyExchangeChecklist = lazy(
  () => import("../pages/customer/currencyExchange/checklist"),
);
const CustomerCurrencyExchangeSelectPurposeCurrency = lazy(
  () => import("../pages/customer/currencyExchange/selectPurposeCurrency"),
);
const CustomerCurrencyExchangeOrderReceived = lazy(
  () => import("../pages/customer/currencyExchange/orederReceived"),
);
const CustomerCreateReceiver = lazy(
  () => import("../pages/customer/newFundTransfer/createReceiver"),
);
const TravelCardLayout = lazy(() => import("../layouts/travelCardLayout"));
const TravelCardSelectLocation = lazy(
  () => import("../pages/public/travelCard/selectLocation"),
);
const TravelCardCustomerDetails = lazy(
  () => import("../pages/public/travelCard/customerDetails"),
);
const TravelCardChecklist = lazy(
  () => import("../pages/public/travelCard/checklist"),
);
const TravelCardOrderReview = lazy(
  () => import("../pages/public/travelCard/orderReview"),
);
const TravelCardPaymentMode = lazy(
  () => import("../pages/public/travelCard/paymentMode"),
);
const TravelCardOrderReceived = lazy(
  () => import("../pages/public/travelCard/orederReceived"),
);
const TravelCardPurpose = lazy(
  () => import("../pages/public/travelCard/purpose"),
);
const CustomerTravelCardPurpose = lazy(
  () => import("../pages/customer/travelCard/purpose"),
);
const CustomerTravelCardSelectLocation = lazy(
  () => import("../pages/customer/travelCard/selectLocation"),
);
const CustomerTravelCardCustomerDetails = lazy(
  () => import("../pages/customer/travelCard/customerDetails"),
);
const CustomerTravelCardChecklist = lazy(
  () => import("../pages/customer/travelCard/checklist"),
);
const CustomerTravelCardOrderReview = lazy(
  () => import("../pages/customer/travelCard/orderReview"),
);
const CustomerTravelCardPaymentMode = lazy(
  () => import("../pages/customer/travelCard/paymentMode"),
);
const CustomerTravelCardOrderReceived = lazy(
  () => import("../pages/customer/travelCard/orederReceived"),
);
const CustomerDashboard = lazy(() => import("../pages/customer/dashboard"));
const CustomerRateAlerts = lazy(() => import("../pages/customer/rateAlerts"));
const AdminManageAgentFx = lazy(() => import("../pages/admin/manageAgentFx"));
const CustomerChangePassword = lazy(
  () => import("../pages/customer/settings/changePassword"),
);
const CustomerNotifications = lazy(
  () => import("../pages/customer/notification"),
);
const CustomerOrderReceived = lazy(
  () => import("../pages/public/orderReceived"),
);
const EnterOtp = lazy(
  () => import("../pages/public/enterOtp"),
);
const VerifyMobileNumber = lazy(
  () => import("../pages/public/verifyMobileNumber"),
);
const UploadKycDetails = lazy(
  () => import("../pages/public/uploadKycDetails"),
);
const NewOrderReview = lazy(
  () => import("../pages/public/orderReview"),
);
const PublicSenderDetails = lazy(
  () => import("../pages/public/senderDetails"),
);
const PublicSenderDetails2 = lazy(
  () => import("../pages/public/receverDetails"),
);
const NewLanding = lazy(
  () => import("../pages/public/landing"),
);
// change here server (../pages/public/server) to landing (../pages/public/newLanding)

const B2BLanding = lazy(
  () => import("../pages/public/b2bLanding‎"),
);

const WhatWeDo = lazy(
  () => import("../pages/public/whatWeDo"),
);

const WhatWeDos= lazy(
  () => import("../pages/customer/newFundTransfer/whatWeDo"),
);

const KycDetails = lazy(
  () => import("../pages/customer/newFundTransfer/kycDetails"),
);
const BlockLiveRate = lazy(
  () => import("../pages/customer/newFundTransfer/blockLiveRate"),
);
const AddKycDetails = lazy(
  () => import("../pages/customer/newFundTransfer/kycDetails/addKycDetails"),
);
const NewOrderReviewTable = lazy(
  () => import("../pages/customer/newFundTransfer/newOrderReview"),
);
const NewSenderDetails = lazy(
  () => import("../pages/customer/newFundTransfer/newSenderDetails"),
);
const NewSenderDetails2 = lazy(
  () => import("../pages/customer/newFundTransfer/newSenderDetails/senderDetails2"),
);
const AgentDashboard = lazy(() => import("../pages/agent/dashboard"));
const BranchDashboard = lazy(() => import("../pages/branch/dashboard"));
const AgentPartnerDashboard = lazy(() => import("../pages/agent-buisness-partner/dashboard"));
const AgentProfile = lazy(
  () => import("../pages/agent/settings/agent-profile"),
);
const AgentPartnerProfile = lazy(
  () => import("../pages/agent-buisness-partner/settings/partner-profile"),
);
const AgentChangePassword = lazy(
  () => import("../pages/agent/settings/change-password"),
);
const AgentSMAPurposeCurrency = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/purposeCurrency"),
);
const AgentSMASenderDetails = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/senderDetails"),
);
const BranchSMASenderDetails = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/senderDetails"),
);
const BranchBuisnessSMASenderDetails = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/senderDetails"),
);
const AgentSMACheckList = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/checklist"),
);

const BranchSMACheckList = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/checklist"),
);
const BranchBuisnessSMACheckList = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/checklist"),
);
const AgentSMAReceiverDetails = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/receiverDetails"),
);
const BranchSMAReceiverDetails = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/receiverDetails"),
);
const BranchBuisnessSMAReceiverDetails = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/receiverDetails"),
);
const AgentSMAOrderReview = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/orderReview"),
);

const BranchSMAOrderReview = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/orderReview"),
);
const BranchBuisnessSMAOrderReview = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/orderReview"),
);
const AgentSMAPaymentMode = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/paymentMode"),
);
const BranchSMAPaymentMode = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/paymentMode"),
);
const BranchBuisnessSMAPaymentMode = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/paymentMode"),
);
const AgentSMAOrderReceived = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/orederReceived"),
);
const BranchSMAOrderReceived = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/orederReceived"),
);
const BranchBuisnessSMAOrderReceived = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/orederReceived"),
);
const AgentCurrencyExchangeSelectPurposeCurrency = lazy(
  () =>
    import(
      "../pages/agent/placeAnOrder/currencyExchange/selectPurposeCurrency"
    ),
);
const AgentCurrencyExchangeSelectLocation = lazy(
  () => import("../pages/agent/placeAnOrder/currencyExchange/selectLocation"),
);
const AgentCurrencyExchangeCustomerDetails = lazy(
  () => import("../pages/agent/placeAnOrder/currencyExchange/customerDetails"),
);
const AgentCurrencyExchangeChecklist = lazy(
  () => import("../pages/agent/placeAnOrder/currencyExchange/checklist"),
);
const AgentCurrencyExchangeOrderReview = lazy(
  () => import("../pages/agent/placeAnOrder/currencyExchange/orderReview"),
);
const AgentCurrencyExchangePaymentMode = lazy(
  () => import("../pages/agent/placeAnOrder/currencyExchange/paymentMode"),
);
const AgentCurrencyExchangeOrderReceived = lazy(
  () => import("../pages/agent/placeAnOrder/currencyExchange/orederReceived"),
);
const AgentTravelCardPurpose = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/purpose"),
);

const BranchTravelCardPurpose = lazy(
  () => import("../pages/branch/placeAnOrder/travelCard/purpose"),
);

const BuissnessPartnerTravelCardPurpose = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/travelCard/purpose"),
);

const AgentTravelCardSelectLocation = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/selectLocation"),
);

const BranchTravelCardSelectLocation = lazy(
  () => import("../pages/branch/placeAnOrder/travelCard/selectLocation"),
);
const AgentTravelCardCustomerDetails = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/customerDetails"),
);

const BranchTravelCardCustomerDetails = lazy(
  () => import("../pages/branch/placeAnOrder/travelCard/customerDetails"),
);

const BuisnessPartnerTravelCardCustomerDetails = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/travelCard/customerDetails"),
);
const AgentTravelCardChecklist = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/checklist"),
);
const AgentTravelCardOrderReview = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/orderReview"),
);
const AgentTravelCardPaymentMode = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/paymentMode"),
);
const AgentTravelCardOrderReceived = lazy(
  () => import("../pages/agent/placeAnOrder/travelCard/orederReceived"),
);
const AgentManageOrders = lazy(() => import("../pages/agent/manageOrders"));
const AgentSMASelectReceiverList = lazy(
  () =>
    import("../pages/agent/placeAnOrder/sendMoneyAbroad/selectReceiverList"),
);

const BranchSMASelectReceiverList = lazy(
  () =>
    import("../pages/branch/placeAnOrder/sendMoneyAbroad/selectReceiverList"),
);
const BranchBuisnessSMASelectReceiverList = lazy(
  () =>
    import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/selectReceiverList"),
);
const AgentSMASelectReceiver = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/selectReceiver"),
);

const BranchSMASelectReceiver = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/selectReceiver"),
);
const BranchBuisnessSMASelectReceiver = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/selectReceiver"),
);
const AgentSMACreateReceiver = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/createReceiver"),
);
const BranchSMACreateReceiver = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/createReceiver"),
);
const BranchBuisnessSMACreateReceiver = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/createReceiver"),
);
const AgentSMAAllReceivers = lazy(
  () => import("../pages/agent/placeAnOrder/sendMoneyAbroad/allReceivers"),
);
const BranchSMAAllReceivers = lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/allReceivers"),
);
const BranchBuisnessSMAAllReceivers = lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/allReceivers"),
);
const AgentAffiliateLinks = lazy(
  () => import("../pages/agent/settings/affiliate-links"),
);
const AgentTermsAndConditions = lazy(
  () => import("../pages/agent/settings/terms-and-conditions"),
);
const BranchSMAPurposeCurrency= lazy(
  () => import("../pages/branch/placeAnOrder/sendMoneyAbroad/purposeCurrency"),
);
const BranchBuisnessSMAPurposeCurrency= lazy(
  () => import("../pages/agent-buisness-partner/placeAnOrder/sendMoneyAbroad/purposeCurrency"),
);
const AgentNotifications = lazy(() => import("../pages/agent/notification"));
const AgentManageUsers = lazy(() => import("../pages/agent/manageUsers"));

const AdminManageUsers = lazy(() => import("../pages/admin/manageUsers"));
const AdminManageOtpUsers = lazy(() => import("../pages/admin/otpUsers"));
const AdminEditUser = lazy(() => import("../pages/admin/manageUsers/editUser"));
const AdminNewUser = lazy(() => import("../pages/admin/newUser"));
const AdminNewUserView = lazy(() => import("../pages/admin/newUser/view"));
const AdminArchivedUsers = lazy(() => import("../pages/admin/archivedUsers"));
const AdminManageExchangeRates = lazy(
  () => import("../pages/admin/manageExchangeRates"),
);
const AdminCreateAdmin = lazy(() => import("../pages/admin/createAdmin"));
const AdminManageReports = lazy(() => import("../pages/admin/manageReports"));
const AdminManageOrders = lazy(() => import("../pages/admin/manageOrders"));
const AdminPartnerBankAllocation = lazy(
  () => import("../pages/admin/partnerBankAllocation"),
);
const AdminMyProfile = lazy(() => import("../pages/admin/settings/myProfile"));
const AdminChangePassword = lazy(
  () => import("../pages/admin/settings/changePassword"),
);
const AdminChannelPartnerGroup = lazy(
  () => import("../pages/admin/settings/channelPartnerGroup"),
);
const AdminManageAgents = lazy(() => import("../pages/admin/manageAgents"));
const AdminManageFxPartner = lazy(() => import("../pages/admin/manageFxpartner"));
const AdminManageAgentFxRate = lazy(() => import("../pages/admin/manageAgentFx"));

const ChannelPartnerDashboard = lazy(
  () => import("../pages/channel-partner/dashboard"),
);
const ChannelPartnerManageOrders = lazy(
  () => import("../pages/channel-partner/manageOrders"),
);
const ChannelPartnerManageCurrencies = lazy(
  () => import("../pages/channel-partner/manageCurrencies"),
);
const ChannelPartnerMyProfile = lazy(
  () => import("../pages/channel-partner/settings/myProfile"),
);
const ChannelPartnerChangePassword = lazy(
  () => import("../pages/channel-partner/settings/changePassword"),
);
const ChannelPartnerNotifications = lazy(
  () => import("../pages/channel-partner/notification"),
);

const MainRoute: React.FC<any> = (props: any) => {
  const { isAppInitialized } = useSelector((state: any) => state.user);

  if (!isAppInitialized) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route
          path={ROUTE_PATH.PUBLIC.HOME} 
          element={
            <Suspense fallback={<Loader />}>
              {/* <Landing /> */}
              <B2BLanding />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_PATH.PUBLIC.PRIVACY_POLICY}
          element={
            <Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_PATH.PUBLIC.TERMS_CONDITIONS}
          element={
            <Suspense fallback={<Loader />}>
              <TermsAndConditions />
            </Suspense>
          }
        />
         <Route
          path={ROUTE_PATH.PUBLIC.ABOUT_US}
          element={
            <Suspense fallback={<Loader />}>
              <AboutUs />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_PATH.PUBLIC.FAQ} 
          element={
            <Suspense fallback={<Loader />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.ORDER_RECEIVED}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerOrderReceived />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.ENTER_OTP}
            element={
              <Suspense fallback={<Loader />}>
                <EnterOtp />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.VERIFY_MOBILE_NUMBER}
            element={
              <Suspense fallback={<Loader />}>
                <VerifyMobileNumber />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.UPLOAD_KYC_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <UploadKycDetails />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.NEW_LANDING}
            element={
              <Suspense fallback={<Loader />}>
                <NewLanding />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.B2B_LANDING}
            element={
              <Suspense fallback={<Loader />}>
                <B2BLanding />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.WHAT_WE_DO}
            element={
              <Suspense fallback={<Loader />}>
                <WhatWeDo />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <NewOrderReview />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.SENDER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <PublicSenderDetails />
              </Suspense>
            }
        />
        <Route
            path={ROUTE_PATH.PUBLIC.SENDER_DETAILS2}
            element={
              <Suspense fallback={<Loader />}>
                <PublicSenderDetails2 />
              </Suspense>
            }
        />

        {/* Auth Routes */}
        <Route path={ROUTE_PATH.AUTH.MAIN} element={<AuthLayout />}>
          <Route
            path={ROUTE_PATH.AUTH.LOGIN}
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.AUTH.REGISTER}
            element={
              <Suspense fallback={<Loader />}>
                <Register />
              </Suspense>
            }
          />
          <Route
            path={`${ROUTE_PATH.AUTH.REGISTER}/r/:referrerCode`}
            element={
              <Suspense fallback={<Loader />}>
                <Register />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.AUTH.OTP}
            element={
              <Suspense fallback={<Loader />}>
                <Otp />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.AUTH.RESET_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.AUTH.FORGOT_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route index element={<Navigate to={ROUTE_PATH.AUTH.LOGIN} />} />
        </Route>

        {/* Send Money Abroad */}
        <Route
          path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.MAIN}
          element={<SendMoneyAbroadLayout />}>
          <Route
            path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.SENDER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <SenderDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.RECEIVER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <ReceiverDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <CheckList />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <OrderReview />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.PAYMENT_MODE}
            element={
              <Suspense fallback={<Loader />}>
                <PaymentMode />
              </Suspense>
            }
          />
        </Route>

        {/* Currency Exchange */}
        <Route
          path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.MAIN}
          element={<CurrencyExchangeLayout />}>
          <Route
            path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.SELECT_LOCATION}
            element={
              <Suspense fallback={<Loader />}>
                <SelectLocation />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.CUSTOMER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <CurrencyExchangeChecklist />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <CurrencyExchangeOrderReview />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.PAYMENT_MODE}
            element={
              <Suspense fallback={<Loader />}>
                <CurrencyExchangePaymentMode />
              </Suspense>
            }
          />
        </Route>

        {/* Trvel Card */}
        <Route
          path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.MAIN}
          element={<TravelCardLayout />}>
          <Route
            path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <TravelCardPurpose />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.SELECT_LOCATION}
            element={
              <Suspense fallback={<Loader />}>
                <TravelCardSelectLocation />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.CUSTOMER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <TravelCardCustomerDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <TravelCardChecklist />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <TravelCardOrderReview />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.PAYMENT_MODE}
            element={
              <Suspense fallback={<Loader />}>
                <TravelCardPaymentMode />
              </Suspense>
            }
          />
        </Route>

        {/* PRIVATE ROUTES */}
        <Route
          path={ROUTE_PATH.PUBLIC.SEND_MONEY_ABROAD.ORDER_RECEIVED}
          element={
            <Suspense fallback={<Loader />}>
              <OrderReceived />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_PATH.PUBLIC.CURRENCY_EXCHANGE.ORDER_RECEIVED}
          element={
            <Suspense fallback={<Loader />}>
              <CurrencyExchangeOrderReceived />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_PATH.PUBLIC.TRAVEL_CARD.ORDER_RECEIVED}
          element={
            <Suspense fallback={<Loader />}>
              <TravelCardOrderReceived />
            </Suspense>
          }
        />

        {/* ADMIN START */}
        <Route path={ROUTE_PATH.PRIVATE.ADMIN.INDEX} element={<AdminLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_AGENTS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageAgents />
              </Suspense>
            }
          />
           <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_FOREX_PARTNER}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageFxPartner />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_AGENT_FX}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageAgentFx />
              </Suspense>
            }
          />

          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_USERS.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageUsers />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_OTP_USERS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageOtpUsers />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_USERS.EDIT_USER}
            element={
              <Suspense fallback={<Loader />}>
                <AdminEditUser />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.NEW_USER.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <AdminNewUser />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.NEW_USER.DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminNewUserView />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.ARCHIVED_USERS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminArchivedUsers />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_EXCHANGE_RATES}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageExchangeRates />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.CREATE_ADMIN_USERS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminCreateAdmin />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_REPORTS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageReports />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_ORDERS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageOrders />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.PARTNER_BANK_ALLOCATION}
            element={
              <Suspense fallback={<Loader />}>
                <AdminPartnerBankAllocation />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.MY_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <AdminMyProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <AdminChangePassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.CHANNEL_PARTNER_GROUP}
            element={
              <Suspense fallback={<Loader />}>
                <AdminChannelPartnerGroup />
              </Suspense>
            }
          />
          <Route
            index
            element={
              <Navigate to={ROUTE_PATH.PRIVATE.ADMIN.MANAGE_USERS.MAIN} />
            }
          />
        </Route>
        {/* ADMIN END */}

        {/* STAFF START */}
        <Route path={ROUTE_PATH.PRIVATE.STAFF.INDEX} element={<StaffLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.STAFF.MANAGE_USERS.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageUsers />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.STAFF.MANAGE_USERS.EDIT_USER}
            element={
              <Suspense fallback={<Loader />}>
                <AdminEditUser />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.STAFF.MANAGE_ORDERS}
            element={
              <Suspense fallback={<Loader />}>
                <AdminManageOrders />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.STAFF.SETTINGS.MY_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <AdminMyProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <AdminChangePassword />
              </Suspense>
            }
          />
          <Route
            index
            element={
              <Navigate to={ROUTE_PATH.PRIVATE.STAFF.MANAGE_USERS.MAIN} />
            }
          />
        </Route>
        {/* STAFF END */}

        {/* CUSTOMER START*/}
        <Route
          path={ROUTE_PATH.PRIVATE.CUSTOMER.INDEX}
          element={<CustomerLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerDashboard />
              </Suspense>
            }
          />

          {/* SEND_MONEY_ABROAD START*/}
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .MAIN
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMAPurposeCurrency />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.KYC_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <KycDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.BLOCK_LIVE_RATE}
            element={
              <Suspense fallback={<Loader />}>
                <BlockLiveRate />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.ADD_KYC_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <AddKycDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.NEW_ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <NewOrderReviewTable />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.NEW_SENDER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <NewSenderDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.WHAT_WE_DOS}
            element={
              <Suspense fallback={<Loader />}>
                <WhatWeDos />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER.NEW_SENDER_DETAILS2}
            element={
              <Suspense fallback={<Loader />}>
                <NewSenderDetails2 />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.ALL_RECEIVERS}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerAllReceivers />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .SELECT_RECEIVER_TYPE
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMASelectReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .SELECT_RECEIVER_LIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMASelectReceiverList />
              </Suspense>
            }
          />

          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .SENDER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMASenderDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .RECEIVER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMAReceiverDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .CHECKLIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMACheckList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMAOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMAPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
                .ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerSMAOrderReceived />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.CREATE_RECEIVER}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCreateReceiver />
              </Suspense>
            }
          />
          {/* CURRENCY EXCHANGE */}
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.RECENT_EXCHANGES
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeMain />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.SELECT_LOCATION}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeSelectLocation />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE
                .SELECT_PURPOSE_CURRENCY
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeSelectPurposeCurrency />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.CUSTOMER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeCustomerDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeChecklist />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeOrderReview />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.PAYMENT_MODE}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangePaymentMode />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.ORDER_RECEIVED}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerCurrencyExchangeOrderReceived />
              </Suspense>
            }
          />
          {/* TRAVEL CARD */}
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardPurpose />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.SELECT_LOCATION}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardSelectLocation />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.CUSTOMER_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardCustomerDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardChecklist />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.ORDER_REVIEW}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardOrderReview />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.PAYMENT_MODE}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.ORDER_RECEIVED}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerTravelCardOrderReceived />
              </Suspense>
            }
          />

          {/* RATE ALERTS */}
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.RATE_ALERTS}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerRateAlerts />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerChangePassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CUSTOMER.NOTIFICATIONS}
            element={
              <Suspense fallback={<Loader />}>
                <CustomerNotifications />
              </Suspense>
            }
          />
          <Route
            index
            element={<Navigate to={ROUTE_PATH.PRIVATE.CUSTOMER.DASHBOARD} />}
          />
        </Route>
        {/* CUSTOMER END*/}

        {/* AGENT START*/}
        <Route path={ROUTE_PATH.PRIVATE.AGENT.INDEX} element={<AgentLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <AgentDashboard />
              </Suspense>
            }
          />

          {/* SEND_MONEY_ABROAD */}
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.MAIN
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMAPurposeCurrency />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SENDER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMASenderDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SELECT_RECEIVER_TYPE
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMASelectReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SELECT_RECEIVER_LIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMASelectReceiverList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.RECEIVER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMAReceiverDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.CHECKLIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMACheckList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMAOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMAPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMAOrderReceived />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .CREATE_RECEIVER
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMACreateReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .ALL_RECEIVERS
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentSMAAllReceivers />
              </Suspense>
            }
          />
        <Route
            path={ROUTE_PATH.PRIVATE.AGENT.MANAGE_USERS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentManageUsers />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.MANAGE_FX_RATE}
            element={
              <Suspense fallback={<Loader />}>
                <AgentManageFxRate />
              </Suspense>
            }
          />
          {/* CURRENCY EXCHANGE  */}
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE.MAIN
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangeSelectPurposeCurrency />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE
                .SELECT_LOCATION
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangeSelectLocation />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE
                .CUSTOMER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangeCustomerDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE
                .CHECKLIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangeChecklist />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE
                .ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangeOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE
                .PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangePaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE
                .ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentCurrencyExchangeOrderReceived />
              </Suspense>
            }
          />

          {/* TRAVEL CARD */}
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardPurpose />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD
                .SELECT_LOCATION
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardSelectLocation />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD
                .CUSTOMER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardCustomerDetails />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardChecklist />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD.ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD.PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD.ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardOrderReceived />
              </Suspense>
            }
          />

          {/* MANAGE ORDERS */}
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.MANAGE_ORDERS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentManageOrders />
              </Suspense>
            }
          />

          {/* SETTINGS */}
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.SETTINGS.AGENT_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <AgentProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <AgentChangePassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.SETTINGS.AFFILIATE_LINKS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentAffiliateLinks />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.SETTINGS.TERMS_AND_CONDITIONS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT.NOTIFICATIONS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentNotifications />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
          <Route
            index
            element={<Navigate to={ROUTE_PATH.PRIVATE.AGENT.DASHBOARD} />}
          />
        </Route>

        {/* AGENT END*/}

        {/* BRANCH START */}
        <Route path={ROUTE_PATH.PRIVATE.BRANCH.INDEX} element={<BranchLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <BranchDashboard />
              </Suspense>
            }
          />
          {/* SMA Start */}
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.MAIN
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMAPurposeCurrency />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SENDER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMASenderDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SELECT_RECEIVER_TYPE
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMASelectReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SELECT_RECEIVER_LIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMASelectReceiverList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.RECEIVER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMAReceiverDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.CHECKLIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMACheckList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMAOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMAPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMAOrderReceived />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .CREATE_RECEIVER
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMACreateReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .ALL_RECEIVERS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchSMAAllReceivers />
              </Suspense>
            }
          />
          {/* SMA End */}

           {/* TRAVEL CARD */}
           <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <BranchTravelCardPurpose />
              </Suspense>
            }
          />
          {/* <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD
                .SELECT_LOCATION
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchTravelCardSelectLocation />
              </Suspense>
            }
          /> */}
         <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD
                .CUSTOMER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchTravelCardCustomerDetails />
              </Suspense>
            }
          />
          {/*  <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardChecklist />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardOrderReceived />
              </Suspense>
            }
          /> */}

          <Route
            path={ROUTE_PATH.ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
           {/* SETTINGS */}
           <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.AGENT_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <AgentProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <AgentChangePassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.AFFILIATE_LINKS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentAffiliateLinks />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.TERMS_AND_CONDITIONS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTermsAndConditions />
              </Suspense>
            }
          />
          <Route
            index
            element={<Navigate to={ROUTE_PATH.PRIVATE.BRANCH.DASHBOARD} />}
          />
        </Route>
        {/* BRANCH END */}

        {/* AGENT BUSINESS PARTNER START */}
        <Route path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.INDEX} element={<AgentBusinessLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <AgentPartnerDashboard />
              </Suspense>
            }
          />
           {/* SMA Start */}
           <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.MAIN
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMAPurposeCurrency />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SENDER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMASenderDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SELECT_RECEIVER_TYPE
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMASelectReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.SELECT_RECEIVER_LIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMASelectReceiverList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.RECEIVER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMAReceiverDetails />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.CHECKLIST
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMACheckList />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMAOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMAPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMAOrderReceived />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .CREATE_RECEIVER
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMACreateReceiver />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .ALL_RECEIVERS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchBuisnessSMAAllReceivers />
              </Suspense>
            }
          />
          {/* SMA End */}

          {/* TRAVEL CARD */}
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.MAIN}
            element={
              <Suspense fallback={<Loader />}>
                <BuissnessPartnerTravelCardPurpose />
              </Suspense>
            }
          />
          {/* <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD
                .SELECT_LOCATION
            }
            element={
              <Suspense fallback={<Loader />}>
                <BranchTravelCardSelectLocation />
              </Suspense>
            }
          /> */}
         <Route
            path={
              ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.PLACE_AN_ORDER.TRAVEL_CARD
                .CUSTOMER_DETAILS
            }
            element={
              <Suspense fallback={<Loader />}>
                <BuisnessPartnerTravelCardCustomerDetails />
              </Suspense>
            }
          />
          {/*  <Route
            path={ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardChecklist />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.ORDER_REVIEW
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardOrderReview />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.PAYMENT_MODE
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardPaymentMode />
              </Suspense>
            }
          />
          <Route
            path={
              ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.ORDER_RECEIVED
            }
            element={
              <Suspense fallback={<Loader />}>
                <AgentTravelCardOrderReceived />
              </Suspense>
            }
          /> */}
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.SETTINGS.AGENT_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <AgentPartnerProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
           {/* SETTINGS */}
           <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.SETTINGS.AGENT_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <AgentProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <AgentChangePassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.SETTINGS.AFFILIATE_LINKS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentAffiliateLinks />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.SETTINGS.TERMS_AND_CONDITIONS}
            element={
              <Suspense fallback={<Loader />}>
                <AgentTermsAndConditions />
              </Suspense>
            }
          />
          <Route
            index
            element={<Navigate to={ROUTE_PATH.PRIVATE.AGENT_BUSINSS_BRANCH.DASHBOARD} />}
          />
        </Route>
        {/* AGENT BUSINESS PARTNER END */}

        {/* Forex Partner START */}
        <Route
          path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.INDEX}
          element={<ChannelPartnerLayout />}>
          <Route
            path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <ChannelPartnerDashboard />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.MANAGE_ORDERS}
            element={
              <Suspense fallback={<Loader />}>
                <ChannelPartnerManageOrders />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.MANAGE_CURRENCIES}
            element={
              <Suspense fallback={<Loader />}>
                <ChannelPartnerManageCurrencies />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <ChannelPartnerMyProfile />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.CHANGE_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ChannelPartnerChangePassword />
              </Suspense>
            }
          />
          <Route
            path={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.NOTIFICATIONS}
            element={
              <Suspense fallback={<Loader />}>
                <ChannelPartnerNotifications />
              </Suspense>
            }
          />
          <Route
            index
            element={
              <Navigate to={ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.DASHBOARD} />
            }
          />
        </Route>
        {/* Forex Partner END */}

        <Route
          path={ROUTE_PATH.ERROR.ERROR_403}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={403} />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_PATH.ERROR.CATCH_ALL}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={404} />
            </Suspense>
          }
        />
        {/* <Route index element={<Navigate to={ROUTE_PATH.PUBLIC.HOME} />} /> */}
        <Route index element={<Navigate to={ROUTE_PATH.PUBLIC.B2B_LANDING} />} />
        
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
